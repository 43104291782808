import React from "react";

const Footer = () => {
  return (
    <div className="text-center bg-tommy-background h-14">
      <h2>© 2024 EtherSteel Software. All rights reserved.</h2>
    </div>
  );
};

export default Footer;
